.project-card {
  width: 322px;
}

.project-card a:hover {
  color: white;
}

.project-card img {
  width: 320px;
  height: 320px;
}

.project-card a {
  min-height: 100%;
}
